<template>
  <el-container>
    <BaaSGovernanceAccount />
  </el-container>
</template>

<script>
import { BaaSGovernanceAccount } from '@/components/console/Index.vue';

export default {
  components: {
    BaaSGovernanceAccount,
  },
};
</script>
